import { render, staticRenderFns } from "./stack.vue?vue&type=template&id=7c171cea&scoped=true&"
import script from "./stack.vue?vue&type=script&lang=js&"
export * from "./stack.vue?vue&type=script&lang=js&"
import style0 from "./stack.vue?vue&type=style&index=0&id=7c171cea&prod&lang=scss&scoped=true&"
import style1 from "./stack.vue?vue&type=style&index=1&id=7c171cea&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c171cea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VIcon,VMenu,VTextField,VTextarea})
